<template>
  <div class="tv">
    <div class="info" :class="{ 'info__hidden': showEmployee }">
      <div class="info__widgets" v-if="isInfoWidgets">
        <div class="weather">
          <iframe src="https://api.wo-cloud.com/content/widget/?geoObjectKey=14353169&language=ru&region=UA&timeFormat=HH:mm&windUnit=mps&systemOfMeasurement=metric&temperatureUnit=celsius" name="CW2" scrolling="no" width="290" height="318" frameborder="0" style="border: 1px solid #E6EFF3;border-radius: 8px"></iframe>
        </div>
        <!-- <div class="conv">
          <iframe style="width:100%;border:0;overflow:hidden;background-color:transparent;height:302.062px" scrolling="no" src="https://fortrader.org/informers/getInformer?st=1&cat=7&title=%D0%9A%D1%83%D1%80%D1%81%D1%8B%20%D0%B2%D0%B0%D0%BB%D1%8E%D1%82%20%D0%A6%D0%91%20%D0%A0%D0%A4&texts=%7B%22toolTitle%22%3A%22%D0%92%D0%B0%D0%BB%D1%8E%D1%82%D0%B0%22%2C%22todayCourse%22%3A%22RUB%22%7D&mult=1.08&showGetBtn=0&hideHeader=0&hideDate=1&w=0&codes=1&colors=false&items=2%2C21%2C30%2C11&columns=todayCourse&toCur=11111"></iframe>
        </div> -->
        <div v-if="isInfoWidgets" style="margin: 0 auto">
          <button class="btn" @click="() => isInfoWidgets = false">Скрыть</button>
          <button class="btn" v-if="!canPlayAudio" @click="() => canPlayAudio = true">Включить звук</button>
        </div>
      </div>
      <!-- <div>Казань: {{ localDate(3) }}</div>
      <div>Самара: {{ localDate(4) }}</div>
      <div>Уфа: {{ localDate(5) }}</div> -->
    </div>
    <div v-if="!imageLink" class="map" ref="map"></div>
    <div class="slider" style="display: none" ref="slider"></div>
    <div class="slider-before" style="display: none" ref="slider-before"></div>
    <div v-if="showEmployee" class="employee" :class="{ 'employee__show': showEmployee }">
      <div class="employee__info">
        <div class="employee__avatar">
          <img v-if="showEmployee.responsible_id" :src="`${baseUrl}/avatar?user=${showEmployee.responsible_id}`" alt="">
        </div>
        <div class="employee__contract">
          <div class="employee__contract__item">
            <span></span>
            <span>{{ showEmployee.responsible }}</span>
          </div>
          <div class="employee__contract__item">
            <span></span>
            <span>{{ showEmployee.subdivision }}</span>
          </div>
          <div class="employee__contract__item">
            <span></span>
            <span>{{ showEmployee.name }}</span>
          </div>
          <div v-if="showEmployee.type" class="employee__contract__item">
            <span></span>
            <span>{{ showEmployee.type }}</span>
          </div>
          <div class="employee__contract__item">
            <span></span>
            <span style="font-size: 36px">{{ showEmployee.price }} руб.</span>
          </div>
          <div v-if="showEmployee.deltaDate" class="employee__contract__item">
            <span></span>
            <span>Срок проекта: {{ deltaDateTransform(showEmployee.deltaDate) }}</span>
          </div>
        </div>
      </div>
      <div class="employee__seal">Выдано</div>
    </div>
    <div v-if="thisNotification" class="tv-notification">
      <div v-if="thisNotification.type === 'news'" class="tv-notification-birthdayUsers">
        <div class="tv-notification-birthdayUsers__data">
          <div class="tv-notification-birthdayUsers__item">
            <span></span>
            <span>{{ thisNotification.title }}</span>
          </div>
        </div>
        <div class="tv-notification-birthdayUsers__avatar">
          <img :src="`${baseUrl}/api/users/file?filename=${thisNotification.filename}`" :alt="`${baseUrl}/api/users/file?filename=${thisNotification.filename}`">
        </div>
        <div class="tv-notification-birthdayUsers__data">
          <div style="font-size: 16px;font-weight: 700;line-height: normal" v-html="thisNotification.text"></div>
        </div>
      </div>
      <div v-if="thisNotification.type === 'birthdayUsers'" class="tv-notification-birthdayUsers">
        <div class="tv-notification-birthdayUsers__data">
          <div class="tv-notification-birthdayUsers__item">
            <span></span>
            <span>ДЕНЬ РОЖДЕНИЯ</span>
          </div>
        </div>
        <div class="tv-notification-birthdayUsers__avatar">
          <img v-if="thisNotification.id" :src="`${baseUrl}/avatar?user=${thisNotification.user_id}`" alt="">
        </div>
        <div class="tv-notification-birthdayUsers__data">
          <div class="tv-notification-birthdayUsers__item">
            <span></span>
            <span>{{ thisNotification.name }}</span>
          </div>
          <div v-if="thisNotification.birthday" class="tv-notification-birthdayUsers__item">
            <span></span>
            <span>{{ formatBirthDate(thisNotification.birthday) }}</span>
          </div>
          <div v-if="thisNotification.subdivision" class="tv-notification-birthdayUsers__item">
            <span></span>
            <span>{{ thisNotification.subdivision }}</span>
          </div>
          <div v-if="thisNotification.department" class="tv-notification-birthdayUsers__item">
            <span></span>
            <span>{{ thisNotification.department }}</span>
          </div>
        </div>
      </div>
      <div v-else-if="thisNotification.type === 'newEmployees'" class="tv-notification-newEmployees">
        <div class="tv-notification-newEmployees__data">
          <div class="tv-notification-newEmployees__item">
            <span></span>
            <span>НОВЫЙ СОТРУДНИК</span>
          </div>
        </div>
        <div class="tv-notification-newEmployees__avatar">
          <img v-if="thisNotification.id" :src="`${baseUrl}/avatar?user=${thisNotification.user_id}`" alt="">
        </div>
        <div class="tv-notification-newEmployees__data">
          <div class="tv-notification-newEmployees__item">
            <span></span>
            <span>{{ thisNotification.name }}</span>
          </div>
          <div v-if="thisNotification.email" class="tv-notification-newEmployees__item">
            <span></span>
            <span>{{ thisNotification.email }}</span>
          </div>
          <div v-if="thisNotification.post" class="tv-notification-newEmployees__item">
            <span></span>
            <span>{{ thisNotification.post }}</span>
          </div>
          <div v-if="thisNotification.subdivision" class="tv-notification-newEmployees__item">
            <span></span>
            <span>{{ thisNotification.subdivision }}</span>
          </div>
          <div v-if="thisNotification.department" class="tv-notification-newEmployees__item">
            <span></span>
            <span>{{ thisNotification.department }}</span>
          </div>
        </div>
      </div>
      <div v-else-if="thisNotification.type === 'report'" class="tv-notification-report">
        <div v-if="thisNotification.users" class="tv-notification-report__data">
          <div class="tv-notification-report__item">
            <span></span>
            <span>ТОП СОТРУДНИКОВ</span>
          </div>
          <div class="tv-notification-report__table">
            <div
              class="tv-notification-report__item tv-notification-report__item--top"
              v-for="(elem, index) in thisNotification.users"
              :key="`${elem.name}__${index}`"
            >
              <div v-if="index < 3" class="tv-notification-report__item__number" :class="`tv-notification-report__item__number--${index + 1}`">
                <span>{{ index + 1 }}</span>
              </div>
              <div v-else class="tv-notification-report__item__number">
                <span>{{ index + 1 }}</span>
              </div>
              <div class="tv-notification-report__item__name">
                <span>{{ cutName(elem.name) }}</span>
              </div>
              <div class="tv-notification-report__item__sum">
                <span>{{ elem.sum.toLocaleString() }} ₽</span>
              </div>
              <!-- <div class="tv-notification-report__item__count">
                <span>{{ elem.count }}</span>
              </div> -->
            </div>
          </div>
        </div>
        <div v-if="thisNotification.groups" class="tv-notification-report__data">
          <div class="tv-notification-report__item">
            <span></span>
            <span>ТОП ПОДРАЗДЕЛЕНИЙ</span>
          </div>
          <div class="tv-notification-report__table">
            <div
              class="tv-notification-report__item tv-notification-report__item--top"
              v-for="(elem, index) in sortGroups(thisNotification.groups)"
              :key="`${elem.groupname}__${index}`"
            >
              <div v-if="index < 3" class="tv-notification-report__item__number" :class="`tv-notification-report__item__number--${index + 1}`">
                <span>{{ index + 1 }}</span>
              </div>
              <div v-else class="tv-notification-report__item__number">
                <span>{{ index + 1 }}</span>
              </div>
              <div class="tv-notification-report__item__name">
                <span>{{ elem.groupname }}</span>
              </div>
              <div class="tv-notification-report__item__sum">
                <span>{{ elem.sum.toLocaleString() }} ₽</span>
              </div>
              <!-- <div class="tv-notification-report__item__count">
                <span>{{ elem.count }}</span>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DG from '2gis-maps'
import sound1 from '@/assets/sounds/fake-applause.mp3'
import sound2 from '@/assets/sounds/bells.mp3'
import smsAlertSound from '@/assets/sounds/sms-alert.mp3'

import GraphQLHelper from '@/helpers/GraphQLHelper'
import NOTIFICATIONS from '@/graphql/queries/TvNotifications.gql'

import axios from 'axios'

const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'TV',
  data: () => {
    return {
      isInfoWidgets: true,
      map: null,
      mapConfig: {
        'center': [55.781241, 49.105596], // [55.785224, 49.091383]
        'zoom': 14,
        'preferCanvas': true,
        'fullscreenControl': false,
        'zoomControl': false
      },
      imagesLinks: [],
      imageLink: null,
      showEmployee: null,
      notificationList: [],
      nowMinutes: null,
      nowHours: null,
      thisNotification: null,
      baseUrl: process.env.VUE_APP_HTTP,
      canPlayAudio: false,
      audio: new Audio(sound1), // mp3/soundbible-person-whistling-at-girl-daniel_simon.mp3
      fanfarAudio: new Audio(sound2), // http://download-sounds.ru/wp-content/uploads2/2012/05/21.mp3
      smsAlertAudio: new Audio(smsAlertSound)
    }
  },
  components: {},
  beforeCreate () {
    if (['partner', 'agent'].includes(this.$store.state.me.role)) this.$router.push({ path: '/' })
  },
  mounted () {
    this.initMap()
    this.$store.state.isHiddenHeadAndSideBar = true

    setInterval(() => {
      this.nowMinutes = new Date().getMinutes()
      this.nowHours = new Date().getHours()
    }, 30_000)

    this.getImagesLinks()
      .then((res) => {
        this.imagesLinks = res
        if (this.imagesLinks.length) this.repeatSlider()
      })
  },
  beforeDestroy () {
    if (this.map) this.map = null
    if (this.$refs.map) this.$refs.map.remove()
    if (this.imagesLinks) this.imagesLinks = []
    if (this.imageLink) this.imageLink = []
    if (this.$refs.slider) this.$refs.slider.remove()
  },
  watch: {
    nowHours: async function (newValue, oldValue) {
      if (newValue % 2 === 1 && this.nowMinutes === 0) {
        await this.getNotificationList()
      }
    },
    '$store.state.tv.employee': function (newValue, oldValue) {
      if (
        newValue &&
        typeof newValue === 'object' &&
        newValue.name &&
        newValue.price &&
        newValue.responsible_id &&
        newValue.responsible &&
        newValue.subdivision
      ) {
        setTimeout(() => {
          this.showEmployee = newValue
        }, 2000)

        this.audioPlay()
      }
      if (this.$store.state.tv.employee) {
        setTimeout(() => {
          this.hiddenEmployee()
        }, 60000)
      }
    },
    '$store.state.tv.notification': async function (newValue, oldValue) {
      if (newValue) {
        await this.getNotificationList()
          .then(() => { this.$store.state.tv.notification = false })
      }
    }
  },
  methods: {
    repeatSlider () {
      let imageIterator = 0

      this.updateImage(imageIterator)
      imageIterator++

      setInterval(() => {
        if (imageIterator > (this.imagesLinks.length - 1)) {
          imageIterator = 0
        }
        this.updateImage(imageIterator)
        imageIterator++
      }, 30000)
    },
    updateImage (count) {
      this.imageLink = `${this.baseUrl}/api/image/tv-image?filename=${this.imagesLinks[count]}`
      this.$refs.slider.style.backgroundImage = `url(${this.imageLink})`
      this.$refs.slider.style.display = 'block'
      this.$refs['slider-before'].style.backgroundImage = `url(${this.imageLink})`
      this.$refs['slider-before'].style.display = 'block'
    },
    async getImagesLinks () {
      try {
        const imagesUrl = `${this.baseUrl}/api/image/tv-images-links`
        const imagesLinks = await axios.get(imagesUrl)
        return imagesLinks.data
      } catch (err) {
        return []
      }
    },
    cutName (name) {
      const splitName = name.split(' ')
      if (splitName.length === 2) return name

      const joinedName = `${splitName[0]} ${splitName[1]}`

      if (joinedName.length < 25) return joinedName

      return `${joinedName.substring(0, 25)}...`
    },
    sortGroups (groupsObj) {
      const keys = Object.keys(groupsObj)
      const groupsArr = []
      for (let i = 0; i < keys.length; i++) {
        let sum = 0
        let count = 0
        for (let j = 0; j < groupsObj[keys[i]].length; j++) {
          sum = sum + groupsObj[keys[i]][j].sum
          count = count + groupsObj[keys[i]][j].count
        }
        groupsArr.push({
          groupname: keys[i],
          sum,
          count
        })
      }

      const result = groupsArr.sort((a, b) => (a.sum < b.sum ? 1 : -1))

      return result
    },
    showNotification (data) {
      if (!data.length) {
        this.notificationList = []
        this.thisNotification = null
        return
      } else {
        data = [...data, null]
      }

      data.forEach((elem, index) => {
        setTimeout(() => {
          this.thisNotification = elem
          if (elem) this.smsAlertAudio.play()
        }, 20_000 * index)
      })
    },
    async getNotificationList () {
      await this.$apollo.mutate({
        mutation: NOTIFICATIONS,
        update: (store, { data }) => {
          if (data) {
            this.notificationList = data.getNotifications
            this.showNotification(data.getNotifications)
          }
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    },
    audioPlay () {
      this.fanfarAudio.play()
        .then(() => {
          this.fanfarAudio.onended = () => {
            this.audio.play()
          }
        })
        .catch(() => {
          this.audio.play()
        })
    },
    hiddenEmployee () {
      this.$store.state.tv.employee = this.showEmployee = null
    },
    // hiddenNotification () {
    //   this.$store.state.tv.notification = false
    //   this.thisNotification = null
    // },
    formatDate (date) {
      return `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
    },
    formatBirthDate (date) {
      date = new Date(date)
      const options = { month: 'long', day: 'numeric' }
      return date.toLocaleDateString('ru-RU', options)
    },
    localDate (plusUTC) {
      const nowMilliseconds = new Date().getMilliseconds()
      const plusUTCMilliseconds = plusUTC * 60 * 60 * 1000
      const date = this.formatDate(new Date(plusUTCMilliseconds + nowMilliseconds))
      return date
    },
    deltaDateTransform (days) {
      const daysNum = +days

      if (isNaN(daysNum)) return days

      const lastSymbol = daysNum[daysNum.length - 1]

      switch (lastSymbol) {
        case 1:
          return `${daysNum} день`
        case 2 || 3 || 4:
          return `${daysNum} дня`
      }

      return `${daysNum} дней`
    },
    initMap () {
      this.map = DG.map(this.$refs.map, this.mapConfig)
      this.mapTraffic()
      this.mapOfficeMarker()
    },
    mapTraffic () {
      const traffic = new DG.Traffic()
      traffic.addTo(this.map)
      const trafficControl = DG.control.traffic()
      trafficControl.remove()
      // trafficControl._active = true
      // trafficControl.addTo(this.map)
    },
    mapOfficeMarker () {
      const officeMarker = DG.marker([55.781241, 49.105596])
      officeMarker.addTo(this.map)
      // officeMarker
      //   .bindLabel('Я статическая подсказка!', {
      //     static: true
      //   })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .tv-notification {
    position fixed
    top 0
    left 0

    display flex
    justify-content center
    align-items center

    width 100vw
    height 100vh
    background-color $black

    opacity 1
    transition 3s /*Скорость перехода состояния элемента*/
    z-index 99999

    &-report {
      height 160%
      min-width 150%
      // max-width 50vw
      background linear-gradient(45deg, rgb(255, 136, 0), rgb(255, 255, 255) 100%)
      box-shadow 0 7px 30px rgba(0,0,0,.16)
      border-radius 10px
      display flex
      flex-direction column
      justify-content center
      align-items center

      transform scale(0.5)
      transform-origin 50% 50%

      &__table {
        display flex
        width 80%

        margin 0 auto
        flex-direction column
        justify-content center
        align-items flex-start
      }

      // &__table > table {
      //   width 100%

      //   & > tr  {
      //     // display flex
      //     // flex-direction row
      //     // flex-wrap nowrap
      //     // justify-content space-between

      //     & > td {
      //       font-family Montserrat-Medium
      //       font-size 28px
      //       font-weight 700
      //       line-height normal
      //     }
      //   }
      // }

      &__data {
        display flex
        flex-direction column
        // align-items space-between
        background rgba(255, 255, 255, .5)
        height 80%
        padding 40px 10px
        width 80%
      }

      &__item {
        display flex
        flex-direction row
        flex-wrap wrap
        justify-content center // space-between
        align-items center
        // white-space nowrap
        // overflow hidden
        // text-overflow ellipsis

        > span:nth-child(2) {
          display inline-block
          // text-align end
          // margin-left auto
          white-space nowrap
          overflow hidden
          text-overflow ellipsis
          // text-decoration underline
        }

        &:not(:last-child) {
          margin-bottom 20px
        }

        > span {
          font-family Montserrat-Medium
          font-size 32px
          font-weight 700
          line-height normal
        }

        &--top {
          display flex

          font-family Montserrat-Medium
          font-size 32px
          font-weight 700
          line-height normal
          flex-direction row
          justify-content space-between
          width 100%

          &:not(:last-child) {
            margin-bottom 40px
          }
        }

        &__sum {
          display flex
          font-size 32px

          // padding 5px
          margin-left 20px
          align-items center
          border-radius 10px

          background rgba(255, 128, 0, 0.2)

          > span {
            display inline-block
            margin 5px
          }
        }

        &__number {
          display flex
          justify-content center
          align-items center
          width 40px
          height 40px

          margin-right 20px

          &--1 {
            background #ffd700
            border-radius 50%
          }
          &--2 {
            background #c0c0c0
            border-radius 50%
          }
          &--3 {
            background #cd7f32
            border-radius 50%
          }
        }
      }
    }

    &-birthdayUsers,
    &-newEmployees {
      height 160%
      width 1000px
      // max-width 50vw
      background linear-gradient(45deg, rgb(255, 136, 0), rgb(255, 255, 255) 100%)
      box-shadow 0 7px 30px rgba(0,0,0,.16)
      border-radius 10px
      display flex
      flex-direction column
      padding 20px
      justify-content space-around

      transform scale(0.5)
      transform-origin 50% 50%

      &__avatar {
        display flex
        min-height 50%
        max-height 80%
        width 100%
        border-top-right-radius 10px
        border-top-left-radius 10px

        > img {
          object-fit cover
          border-top-right-radius 10px
          border-top-left-radius 10px
        }
      }

      &__data {
        display flex
        flex-direction column
        justify-content space-between
        // align-items space-between
        border-radius 20px
        background rgba(255, 255, 255, .5)
        // min-height 20%
        max-height 50%
        padding 40px 10px
        max-width 100%
      }

      &__item {
        display flex
        flex-direction row
        flex-wrap wrap
        justify-content center // space-between
        // white-space nowrap
        // overflow hidden
        // text-overflow ellipsis

        > span:nth-child(2) {
          display inline-block
          // text-align end
          // margin-left auto
          white-space nowrap
          overflow hidden
          text-overflow ellipsis
          // text-decoration underline
        }

        &:not(:last-child) {
          margin-bottom 10px
        }

        > span {
          font-family Montserrat-Medium
          font-size 32px
          font-weight 700
          line-height normal
        }
      }
    }
  }

  .tv {
    display flex
    position fixed
    top 0
    left 0
    width 100vw
    height 100vh
    z-index 999
  }

  .info {
    display flex
    flex-direction column
    position absolute
    z-index 99999
    bottom 50px
    left 50px
    background $white
    box-shadow 0 7px 30px rgba(0,0,0,.16)
    border-radius 10px
    min-height 300px
    // max-height 90vh
    max-width 640px
    padding 10px
    transition 1s
    transform scale(0.6)
    transform-origin 0 100%

    &__title {
      width 100%
      text-align center
    }

    &__widgets {
      display flex
      flex-direction column
      justify-content space-between
    }

    &__hidden {
      display none
    }
  }

  .map {
    height 100%
    width 100%
  }
  .slider {
    height 100%
    width 100%
    // background-image url('http://192.168.41.40:4000/api/image/tv-image?filename=1cd9a4c5-da9d-4282-958f-2df83603cc8d.jpeg')
    background-size contain
    background-repeat no-repeat
    background-position: center center
    transition: background-image 0.5s ease

    &-before {
      content: ""
      position: fixed
      top: 0
      left: 0
      width: 100%
      height: 100%
      z-index: -1
      background-color: $black
      background-size: cover
      background-position: center center
      transition: background-image 0.5s ease
      filter: blur(10px)
    }
  }

  .weather {
    width 50%
  }

  .dg-control-round {
    height 100px !important
    width 100px !important
  }

  .employee {
    position fixed
    top 0
    left 0

    display flex
    justify-content center
    align-items center

    width 100vw
    height 100vh
    background-color $black

    transition 3s

    opacity 0

    &__seal {
      position absolute
      display inline-block
      top calc(50vh - 50px)
      right calc(50vw - 275px)
      border 2px solid $green
      color $green
      font-size 72px
      background rgba(111, 207, 151, .2)
      padding 20px 5px
      transform scale(0.5) rotate(-20deg)

      // transform scale(0.5)
      // transform-origin 0 0
    }

    &__contract {
      display flex
      flex-direction column
      justify-content space-between
      // align-items space-between
      height 100%
      padding 40px 10px
      max-width 50%

      &__item {
        display flex
        flex-direction row
        flex-wrap wrap
        justify-content center // space-between
        // white-space nowrap
        // overflow hidden
        // text-overflow ellipsis

        > span:nth-child(2) {
          display inline-block
          // text-align end
          // margin-left auto
          white-space nowrap
          overflow hidden
          text-overflow ellipsis
          // text-decoration underline
        }

        &:not(:last-child) {
          margin-bottom 10px
        }

        > span {
          font-family Montserrat-Medium
          font-size 28px
          font-weight 700
          line-height normal
        }
      }
    }

    &__info {
      height 160%
      width 1000px
      // max-width 50vw
      background-color $white
      box-shadow 0 7px 30px rgba(0,0,0,.16)
      border-radius 10px
      display flex
      flex-direction row
      justify-content space-between

      transform scale(0.5)
      transform-origin 50% 50%
    }
    z-index 1
    opacity 0

    &__avatar {
      display flex
      height 100%
      width 500px
      border-top-right-radius 10px
      border-top-left-radius 10px

      > img {
        object-fit cover
        border-top-right-radius 10px
        border-top-left-radius 10px
      }
    }

    &__show {
      opacity 1
      transition 3s /*Скорость перехода состояния элемента*/
      z-index 99999
    }
  }
</style>
